.cargo-header {
  margin-top: -36px !important;
  margin-right: -24px !important;
  margin-left: -24px !important;
  background: #fff;

  h1 {
    margin: 0 !important;
  }

  .content {
    padding-left: 16px;
  }

  .cargo-header-column {
    width: 100% !important;
    padding: 16px 24px !important;

    @media only screen and (min-width: 1024px) {
      width: 50% !important;
    }
  }
}

.header-breadcrumbs {
  margin-top: 8px;
  line-height: 14px;

  .breadcrumb {
    margin: 0 !important;
  }

  .icon.divider {
    font-size: 9px !important;
  }

  a {
    color: #0077C8 !important;
    font-weight: 400;
  }
}

.header__title {
  font-size: 20px;
  line-height: 20px;
}
