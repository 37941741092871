html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 30px;
  color: white;
  font-family: 'Roboto', sans-serif;
  background: #2C3E50;
}

.landing-title {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}

.landing-subtitle {
  font-size: 24px;
  text-align: center;
}

.landing-divider {
  width: 100px;
  height: 8px;
  margin-top: 80px;
  margin-bottom: 60px;
  border-radius: 3px;
  background-color: #1AB5E2;
}

.login-platform-button {
  color: white;
  padding: 15px 32px;
  display: inline-block;
  margin: 30px 2px;
  cursor: pointer;
}

.invitation-row {
  padding-top: 0!important;
  padding-bottom: 0!important;
}

@media only screen and (max-width: 768px) {
  .landing-title {
    font-size: 22px;
  }

  .landing-subtitle {
    font-size: 16px;
  }

  .landing-divider {
    width: 60px;
    height: 5px;
    margin: 50px 0;
  }

  .landing-logo {
    width: 120px;
  }
}
