.app-badge-android {
  display: inline-block;
  width: 135px;
  height: 60px;
}

.app-badge-ios {
  display: inline-block;
  overflow: hidden;
  width: 135px;
  height: 40px;
  margin-bottom: 10px;
}

.app-badge-login {
  text-align: center;
  margin-top: 15px;
}