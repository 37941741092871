.order-information {
  &.ui.segment {
    padding: 0;
    border: none;
    box-shadow: none;
    background: none;
  }

  .ui.segment {
    padding: 0;
    border: none;
    box-shadow: none;
    background: none;
  }
}

.order-information-tabs {
  padding-right: 14px;
  padding-left: 14px;
  margin-right: -26px !important;
  margin-left: -26px !important;
  background: white !important;

  &.ui.tabular.menu {
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.03) !important;
  }

  @media only screen and (min-width: 1024px) {
    padding-right: 32px;
    padding-left: 32px;
  }

  &.is-desktop {
    display: none;

    @media only screen and (min-width: 1024px) {
      display: flex;
    }
  }

  &.is-mobile {
    display: block;
    padding: 0;

    @media only screen and (min-width: 1024px) {
      display: none;
    }
  }

  .ui.selection.dropdown {
    height: 40px !important;
    padding: 0 14px !important;
    border: none !important;

    .text, .text.default {
      font-size: 16px;
      font-weight: 600;
      line-height: 40px;
      color: #000 !important;
    }

    .menu {
      max-height: 300px !important;
    }
  }
}

.order-information-tabs__item {
  padding-right: 15px !important;
  padding-left: 15px !important;
  border: none;
  background: none;
  font-weight: 600 !important;
  text-transform: uppercase !important;

  &.active {
    color: #1abb9c !important;
    border: none !important;
    border-bottom: 2px solid #1abb9c !important;
    background: none !important;
  }
}

.order-information-actions, .shareable-link {
  display: block;
  margin-top: 32px;
  margin-right: -14px;
  margin-left: -14px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  .button {
    min-width: 80px !important;
    justify-content: center !important;
    padding: 13px 15px !important;
    text-align: center !important;
  }

  form {
    max-height: 40px !important;
  }

  .ui.selection.dropdown {
    height: 40px !important;
  }

  @media only screen and (min-width: 1024px) {
    padding: 0 6px;
    margin-right: 0;
    margin-left: 0;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  .driver-dropdown {
    .text, .text.default {
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      color: #000 !important;
    }
  }
}

.order-information-actions-right {
  display: flex;
  float: right;

  @media only screen and (max-width: 768px) {
    button, .ui.action.input, a {
      margin-right: 0 !important;
    }

    form, .ui.action.input {
      width: 100% !important;
      margin: 0 !important;
    }
  }
}

.order-information-actions-left {
  display: flex;
  float: left;

  button, .ui.action.input, a:last-child {
    margin-right: 16px !important;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 15px;

    button, .ui.action.input, a {
      margin-right: 0 !important;
    }

    form, .ui.action.input {
      width: 100% !important;
      margin: 0 !important;
    }
  }
}

.order-information-content {
  margin-top: 24px !important;
  margin-right: -14px !important;
  margin-left: -14px !important;

  .tab {
    width: 100%;
    padding: 0 14px;

    @media only screen and (min-width: 1024px) {
      padding: 0 20px;
    }
  }

  @media only screen and (min-width: 1024px) {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }
}

.order-information-table {
  border-collapse: collapse !important;

  &.ui.table td.three.wide {
    @media only screen and (max-width: 1024px) {
      width: 37.5% !important;
    }

    @media only screen and (max-width: 767px) {
      width: 100% !important;
    }
  }

  &.ui.table td.five.wide {
    @media only screen and (max-width: 1024px) {
      width: auto !important;
    }
  }

  &.ui.definition.table td:nth-child(2) {
    border-left: none;

    @media only screen and (min-width: 767px) {
      border-right: 1px solid rgba(34, 36, 38, .15);
      border-left: 1px solid rgba(34, 36, 38, .15);
    }
  }

  th {
    padding: 9px 17px;
  }

  tr {
    &.is-main-info {
      border: 2px solid rgba(180, 180, 180, 1);
    }

    &.no-top-border {
      border-top: none;
    }

    &.no-bottom-border {
      border-bottom: none;
    }

    &.is-mobile {
      display: block;

      @media only screen and (min-width: 767px) {
        display: table-row;
      }

      @media only screen and (min-width: 1024px) {
        display: none;
      }
    }

    @media only screen and (max-width: 767px) {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  td {
    padding: 7px 17px !important;
    padding-right: 10px !important;

    &.is-desktop {
      display: none;

      @media only screen and (min-width: 1024px) {
        display: table-cell;
      }
    }

    &.is-mobile {
      display: block;

      @media only screen and (min-width: 767px) {
        display: table-cell;
        border-right: 1px solid rgba(34, 36, 38, .15);
        border-left: 1px solid rgba(34, 36, 38, .15);
      }

      @media only screen and (min-width: 1024px) {
        display: none;
      }
    }

    &.is-payment-cell {
      background: rgba(0, 0, 0, .03);
      border-right: 1px solid rgba(34, 36, 38, .1);
      border-left: 1px solid rgba(34, 36, 38, .1);
      font-weight: bold;
    }

    &.has-right-border {
      border-right: 1px solid rgba(34, 36, 38, .1);
    }

    &.has-left-border {
      border-left: 1px solid rgba(34, 36, 38, .1);
    }

    &.has-border-bottom {
      border-bottom: 1px solid rgba(34, 36, 38, .1);
    }
  }

  @media only screen and (max-width: 767px) {
    display: block;
    width: 100% !important;

    tbody, tr, td, th {
      display: block;
      width: 100% !important;
    }
  }
}

tr.order-information-table-dropdown__toggle {
  width: 100%;

  td {
    background: none !important;
    color: #0077C8 !important;
    cursor: pointer;
    user-select: none;
  }

  i {
    height: auto !important;
    font-size: 10px;
  }

  span, i {
    pointer-events: none;
  }

  ~ tr {
    display: none !important;

    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }

  .is-show-less {
    display: none;
  }

  &.is-active {
    ~ tr {
      display: table-row !important;

      @media only screen and (max-width: 767px) {
        display: block !important;
      }
    }

    i {
      transform: rotate(180deg);
    }

    .is-show-more {
      display: none;
    }

    .is-show-less {
      display: inline-block;
    }
  }
}

.order-information-table__title {
  font-size: 16px;
  font-weight: bold;
}

.order-information-table-dropdown {
  display: flex !important;
  width: 100%;
  padding: 8px 12px;
  border: none;
  background: none;
  border-radius: 0;
}

.order-information-table-photos {
  background: none !important;
  border-top: none !important;
}

.order-information__title {
  margin-bottom: 30px !important;
}

.order-information-rows {
  margin-top: 0 !important;
}

.order-information-column {
  width: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-bottom: 20px;

  @media only screen and (min-width: 1024px) {
    width: 50%;
    padding-right: 8px !important;
    padding-left: 8px !important;

    &.is-full-width {
      width: 100%;
    }
  }
}

.ui.definition.table tr td.definition, .ui.definition.table tr td.comments:first-child:not(.ignored) {
  background: inherit;
  font-weight: normal;
}
