@import "variables";
@import "ui";
@import "header";

.ui.inverted.menu {
  background: $color-menu;
}

#notifications {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1.4;
  width: 600px;
}

.notifications-message {
  margin-right: 15px!important;
}

.button-top {
  margin-top: 25px;
}

.js-ui-message {
  margin-top: 10px!important;
}

.text.gray {
  color: #999;
}

.ui.buttons form .button {
  border-radius: 0 !important;
}

.ui.buttons form:first-child .button {
  border-top-left-radius: .28571429rem !important;
  border-bottom-left-radius: .28571429rem !important;
}

.ui.buttons form:last-child .button {
  border-top-right-radius: .28571429rem !important;
  border-bottom-right-radius: .28571429rem !important;
}
