// ----------------------------------
// ------------ Globals
// ----------------------------------

.ui.inverted.menu {
  background: #1d212b;
}

body.pushable .pusher {
  background: #f9f9f9;
}

#wrapper.full.height {
  padding: 64px 12px 0 12px;
}

a {
  color: $sylius-brand-color;
  &:hover { color: $sylius-brand-color; }
}

// ----------------------------------
// ------------ Buttons
// ----------------------------------

.ui.button {
  font-weight: 400;
}

.ui.primary.button {
  background-color: $sylius-brand-color;
  &:hover, &:focus { background-color: #279c7f; }
}

.ui.labeled.icon.button {
  padding-left: 2.8em !important;
}

.ui.labeled.icon.button>.icon,
.ui.labeled.icon.buttons>.button>.icon {
  width: 2.8em !important;
  background: none !important;
  padding-left: .2em;
}

// ----------------------------------
// ------------ Forms
// ----------------------------------

.ui.form .field > label {
  margin: 0 0 .5rem 0;
  color: rgba(0,0,0,.8);
  font-weight: 400;
}

.ui.form .field > input:focus,
.ui.form .field > textarea:focus {
  border-color: rgba($sylius-brand-color, .4);
}

// ----------------------------------
// ------------ Sidebar menu
// ----------------------------------

#sidebar.ui.sidebar.vertical.menu {
  > .item:first-child {
    margin-bottom: 10px;
  }

  > .item:not(:first-child) {
    margin-right: 10px;
  }

  .item > .header {
    text-transform: uppercase;
    font-size: 11px;
    margin-bottom: 16px;
  }

  .item > i.icon {
    float: none !important;
    margin: 2px 10px 2px 0 !important;
    opacity: .5;
  }

  .item::before {
    display: none;
  }

  .item.active {
    font-weight: inherit !important;
    background: $sylius-brand-color !important;
    border-radius: 0 99px 99px 0 !important;
  }
}

// ----------------------------------
// ------------ Header
// ----------------------------------

.ui.header {
  .content {
    padding-left: 1.3rem !important;
  }

  .content .sub.header {
    font-size: 1rem;
    color: #9a9a9a;
    padding-top: 3px;
  }

  .circular.icon {
    width: 2.5em !important;
    height: 2.5em !important;
    font-size: 1em;
    color: $sylius-brand-color;
    background: rgba($sylius-brand-color, .1);
    box-shadow: none;
  }
}

// ----------------------------------
// ------------ Breadcrumb
// ----------------------------------

.ui.breadcrumb {
  .active {
    color: #9a9a9a;
    font-weight: 400 !important;
  }

  a {
    color: #9a9a9a;
    &:hover { color: $sylius-brand-color; }
  }

  .divider {
    opacity: .4;
  }
}

// ----------------------------------
// ------------ Segment
// ----------------------------------

.ui.segment,
.ui.attached.segment {
  box-shadow: 0 2px 4px 0 rgba(34,36,38,.03);
  border: 1px solid rgba(34,36,38,.1);
  padding: $segment-spacing;
  width: 100%;
}

.ui.attached.segment {
  margin-left: 0;
  margin-right: 0;
}

.ui.attached.header {
  padding: $segment-title-spacing;
  margin: 0;
  box-shadow: 0 2px 4px 0 rgba(34,36,38,.03);
  border: 1px solid rgba(34,36,38,.1);
  border-bottom: 1px solid #eaeaea;
  font-size: inherit;
}

.segment.spaceless {
  padding: 0 !important;
}

// ----------------------------------
// ------------ Accordion
// ----------------------------------

.ui.styled.accordion {
  box-shadow: 0 2px 4px 0 rgba(34,36,38,.03);
  border: 1px solid rgba(34,36,38,.1);

  .title {
    padding: $accordion-title-spacing;
    border-top: none;
  }

  .content {
    padding: $segment-spacing;
    border-top: 1px solid #eaeaea;
  }
}

// ----------------------------------
// ------------ Pagination / Menu
// ----------------------------------

.ui.menu {
  box-shadow: 0 2px 4px 0 rgba(34,36,38,.03);
  border: 1px solid rgba(34,36,38,.1);
}

// ----------------------------------
// ------------ Table
// ----------------------------------

.sylius-grid-table-wrapper {
  .ui.sortable.table {
    thead th {
      padding-top: 20px;
      padding-bottom: 20px;

      &.sorted {
        background: inherit;
        color: $sylius-brand-color;
      }
    }
  }
}

// ----------------------------------
// ------------ Toggle
// ----------------------------------

.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before,
.ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before {
  background-color: $sylius-brand-color !important;
}

// ----------------------------------
// ------------ Custom
// ----------------------------------

.ui.left.attached {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  line-height: normal;
}
