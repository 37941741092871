#mailchimp-form .asterisk {
  color: #e85c41;
  font-size: 150%;
  font-weight: normal;
  position: relative;
  top: 5px;
}

#mailchimp-form #mce-error-response {
  display: none;
}

#mailchimp-form #mce-success-response {
  color: #529214;
  display: none;
}
