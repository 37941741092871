.cargo-fields {
  @media only screen and (max-width: 768px) {
    .fields {
      flex-wrap: wrap;
    }

    .field {
      width: 100% !important;
      margin-bottom: 1em !important;
    }
  }
}

.cargo-fields-delivery {
  @media only screen and (max-width: 1024px) {
    width: calc(100% + 14px) !important;
    flex-wrap: wrap !important;

    .field {
      width: 100% !important;
    }
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 15px !important;

    >.field {
      margin-bottom: 0 !important;
    }
  }
}

.form-authorization-roles {
    display: flex!important;
}
.disabled-input {
  background-color: #eee;
  color: #999;
  border: 1px solid #ccc;
  pointer-events: none;
  opacity: .45;
}
